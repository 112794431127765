<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Contracts</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm" class="pa-3 pb-0">
            <v-autocomplete
              v-model="formData.currency_id_from"
              label="Currency from"
              item-text="text"
              item-value="index"
              :items="serverData.currencies"
              clearable
              outlined
              :error-messages="fromErrors"
              @input="$v.formData.currency_id_from.$touch()"
              @blur="$v.formData.currency_id_from.$touch()"
            ></v-autocomplete>
            <v-autocomplete
              v-model="formData.currency_id_to"
              label="Currency To"
              item-text="text"
              item-value="index"
              :items="serverData.currencies"
              clearable
              outlined
              :error-messages="toErrors"
              @input="$v.formData.currency_id_to.$touch()"
              @blur="$v.formData.currency_id_to.$touch()"
            ></v-autocomplete>

            <v-text-field
              type="number"
              @change="() => validateMinValue('rate', 0, 'formData')"
              v-model="formData.rate"
              label="Exchange Rate"
              clearable
              outlined
              :error-messages="rateErrors"
              @input="$v.formData.rate.$touch()"
              @blur="$v.formData.rate.$touch()"
            ></v-text-field>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalEdited from "@/core/services/AlertServices/SwalEdited";
import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      currency_id_from: { required },
      currency_id_to: { required },
      rate: { required, decimal },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      currency_id_from: null,
      currency_id_to: null,
      rate: null,
    },
  }),
  methods: {
    setServices(item) {
      this.formData.services = [...item];
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/cod/exchange_management/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    validateData() {
      return true;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          ApiService.post("/cod/exchange_management/update", this.formData)
            .then(() => {
              SwalEdited();
              this.toggleModal();
              this.resetEditForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return;
        }
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.currency_converter };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.currency_converter).forEach(
            (serverData) => {
              if (localData[0] == serverData[0]) {
                this.formData[localData[0]] = copy[serverData[0]];
              }
            }
          );
        });
      } else {
        this.formData = {
          id: null,
          currency_id_from: null,
          currency_id_to: null,
          rate: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("decimal" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].decimal &&
          errors.push("This must be a decimal");
      }
      return errors;
    },
  },
  computed: {
    fromErrors() {
      return this.handleFormValidation("currency_id_from", this);
    },
    toErrors() {
      return this.handleFormValidation("currency_id_to", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
  },
};
</script>
