<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Exchange Rate</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-autocomplete
            v-model="formData.currency_id_from"
            label="Currency from"
            item-text="text"
            item-value="index"
            :items="serverData.currencies"
            clearable
            outlined
            :error-messages="fromErrors"
            @input="$v.formData.currency_id_from.$touch()"
            @blur="$v.formData.currency_id_from.$touch()"
          ></v-autocomplete>
          <v-autocomplete
            v-model="formData.currency_id_to"
            label="Currency To"
            item-text="text"
            item-value="index"
            :items="serverData.currencies"
            clearable
            outlined
            :error-messages="toErrors"
            @input="$v.formData.currency_id_to.$touch()"
            @blur="$v.formData.currency_id_to.$touch()"
          ></v-autocomplete>

          <v-text-field
            type="number"
            @change="() => validateMinValue('rate', 0, 'formData')"
            v-model="formData.rate"
            label="Exchange Rate"
            clearable
            outlined
            :error-messages="rateErrors"
            @input="$v.formData.rate.$touch()"
            @blur="$v.formData.rate.$touch()"
          ></v-text-field>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { required, decimal } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      currency_id_from: { required },
      currency_id_to: { required },
      rate: { required, decimal },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      currency_id_from: null,
      currency_id_to: null,
      rate: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    setServices(item) {
      this.formData.services = [...item];
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/cod/exchange_management/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetCreateForm();
    },
    validateData() {
      return true;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          ApiService.post("/cod/exchange_management/store", this.formData)
            .then(() => {
              Swal.fire({
                title: "Created",
                text: `Record has been successfully added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.resetCreateForm();
              this.toggleModal();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        currency_id_from: null,
        currency_id_to: null,
        rate: null,
      };
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("decimal" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].decimal &&
          errors.push("This must be a decimal");
      }
      return errors;
    },
  },
  computed: {
    fromErrors() {
      return this.handleFormValidation("currency_id_from", this);
    },
    toErrors() {
      return this.handleFormValidation("currency_id_to", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
  },
};
</script>
